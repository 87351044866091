import React from "react";
import styled from "styled-components";
import Layout from "../../layouts/Layout";
import WorkExperience from "./work_experience";
import EducationSection from "./education";
import SkillsSection from "./skills";
import Interests from "./interests";
import Awards from "./awards";
import { below } from "../../utilities";
import myResume from "../../files/CarlHinkleResume.pdf";

import Email from "../../../assets/icons/email.inline.svg";
import GitHub from "../../../assets/icons/github.inline.svg";
import LinkedIn from "../../../assets/icons/linkedin.inline.svg";
import Location from "../../../assets/icons/location.inline.svg";
import World from "../../../assets/icons/world.inline.svg";

const ResumeContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 3em;
`;

const ResumeHeader = styled.section`
  line-height: 1.4em;
  border-radius: 3px;
  padding: 2em 1em;
  background: #313d4e;
  display: flex;
  flex-direction: column;

  #title {
    margin-top: 0.3em;
    margin-bottom: 0.3em;
  }

  #subtitle {
    margin-bottom: 1em;
    font-size: 1.2em;
  }

  p {
    line-height: 1.4em;
  }
`;

const ContactArea = styled.section`
  line-height: 1.6em;
  padding: 1em;
  width: 100%;
  background: #222933;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }

  ${below.medium`
    flex-direction: column;
  `};
`;

const ResumeBody = styled.section`
  background-color: white;
  border-radius: 3px;
  width: 100%;
  display: flex;
  flex-direction: row;
  color: black;
  padding: 2em 1em;

  section {
    width: 100%;
  }

  h3 {
    text-decoration: underline;
  }

  .sectionItemTitle {
    font-weight: bold;
    color: black;
  }

  ${below.medium`
    flex-direction: column;
  `};
`;

const ResumeBodyColumn = styled.section`
  article {
    margin-bottom: 2em;
  }

  .titleWrapper {
    line-height: 1.2em;
  }

  .emphasizedSubtitle {
    display: block;
    font-size: 0.8em;
    font-style: italic;
    font-weight: bold;
    color: ${(props) => props.theme.colors.text.headingDark};
  }

  .bulletList {
    .emphasizedSubtitle {
      line-height: 1.1em;
    }

    ul {
      padding: 0 4em 0 1em;
      line-height: 1.3em;
      font-size: 0.9em;
    }

    li {
      margin-bottom: 1.1em;
      ::marker {
        color: ${(props) => props.theme.colors.text.heading};
      }
    }
  }
`;

const LabeledText = styled.span`
  display: flex;
  flex-direction: row;

  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    fill: ${(props) => props.theme.colors.text.body};
    margin-right: 0.2em;
  }

  a {
    display: flex;
    align-items: center;

    svg {
      fill: ${(props) => props.theme.colors.link.default};
    }
  }
`;

const ResumeHeaderContent = () => {
  return (
    <>
      <span>
        <a href={myResume} download>
          Download
        </a>
      </span>
      <h1 id="title">Carl Hinkle</h1>
      <h2 id="subtitle">Senior Software Engineering Manager</h2>
      <p>
        I'm an experienced and forward thinking software engineering manager
        with a history of building teams that produce high-quality, cutting-edge
        applications on the Web and on mobile devices. I have a track record of
        shipping products while helping my team members grow professionally and
        personally, and delivering value for our customers. I'm at my best when
        I can spend some time with my hands in the code, and I thrive in
        dynamic, fast-paced environments.
      </p>
    </>
  );
};

const ResumeContactContent = () => {
  return (
    <>
      <div>
        <section>
          <LabeledText>
            <Email /> carl@carlhinkle.com
          </LabeledText>
          <LabeledText>
            <Location /> New Milford, CT, United States
          </LabeledText>
          <LabeledText>
            <a href="https://linkedin.com/in/carlhinkle">
              <LinkedIn />
              https://linkedin.com/in/carlhinkle
            </a>
          </LabeledText>
        </section>
      </div>
      <div>
        <section>
          <LabeledText>
            <a href="https://carlhinkle.com">
              <World />
              https://carlhinkle.com
            </a>
          </LabeledText>
          <LabeledText>
            <a href="https://github.com/carlh">
              <GitHub />
              https://github.com/carlh
            </a>
          </LabeledText>
        </section>
      </div>
    </>
  );
};

const Resume = () => {
  return (
    <Layout>
      <ResumeContainer>
        <ResumeHeader>
          <ResumeHeaderContent />
        </ResumeHeader>
        <ContactArea>
          <ResumeContactContent />
        </ContactArea>
        <ResumeBody>
          <ResumeBodyColumn>
            <WorkExperience />
            <EducationSection />
          </ResumeBodyColumn>
          <ResumeBodyColumn>
            <SkillsSection />

            <Awards />

            <Interests />
          </ResumeBodyColumn>
        </ResumeBody>
      </ResumeContainer>
    </Layout>
  );
};

export default Resume;
